import styled from 'styled-components';
import { transparentButton } from '../../utils/styled-components/mixins';
import Container from '../Container/Container';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div`
  background: #000;
  position: sticky;
  top: 0;
  z-index: 102;
`;

export const SalonTopbar = styled(Container)`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.lg`
    display:flex;
    padding-top: 10px;
    padding-bottom: 10px;

  `}
`;

export const LinksWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  flex: 1;
  gap: 12px;
`;

export const BasketWrapper = styled.div``;

export const BrandLogo = styled.img`
  max-width: 90px;
  margin-right: 5px;

  ${media.sm`
    margin-right: 10px;
  `}

  ${media.lg`
    margin-right: 55px;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
  ${media.sm`
    gap: 33px;
  `}
`;

export const BurgerMenu = styled.button`
  ${transparentButton}
`;

export const Logo = styled.img`
  height: 30px;
  margin-right: 10px;

  ${media.sm`
    margin-right: 20px;
  `}
`;

export const Link = styled.button`
  ${transparentButton}
  color: white;
  text-transform: uppercase;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 16px;
`;
