import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { basketQuantitySelector, startCartAction, basketAction } from '../store';
import { TSelectedSku } from '../typings';

export const useAddProductToCart = () => {
  const dispatch = useDispatch();
  const basketQuantity = useSelector(basketQuantitySelector);

  return useCallback(
    (lookShortId: string) => (variants: TSelectedSku | TSelectedSku[]) => {
      if (basketQuantity === 0) {
        dispatch(startCartAction({ lookShortId, variants }));
      }

      if (Array.isArray(variants)) {
        variants.forEach((variant) => {
          dispatch(
            basketAction.addToBasket({
              ...variant,
              bundleId: lookShortId,
              quantity: 1,
            }),
          );
        });
      } else {
        dispatch(
          basketAction.addToBasket({
            ...variants,
            bundleId: lookShortId,
            quantity: 1,
          }),
        );
      }
    },
    [basketQuantity],
  );
};
