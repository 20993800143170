import React from 'react';

import { TArtist, EBrand } from '../../typings';

import { HorizontalSocials } from '../Socials';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';
import MACLogo from '../../assets/svg/mac-now-logo.svg';
import AvedaLogo from '../../assets/svg/aveda-logo.svg';
import NoImage from '../../assets/images/profile_placeholder.png';
import * as Ui from './ArtistProfileSection.styles';
import { Skeleton, SkeletonRectangle } from '../Skeleton/Skeleton';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';

type ArtistProfileSectionProps = {
  artist: TArtist;
  brand: EBrand;
  ready?: boolean;
  children: React.ReactNode;
  showLogo?: boolean;
};

export const ArtistProfileSection = ({
  artist,
  brand,
  ready = true,
  children: Basket,
  showLogo = true,
}: ArtistProfileSectionProps) => {
  const renderName = () => {
    if (ready) {
      // @ts-ignore
      return <Ui.Name>{artist.fullName}</Ui.Name>;
    }
    return (
      <Ui.Name>
        <Skeleton style={{ width: '100px', height: '26px' }}>
          <SkeletonRectangle width="100%" height="100%" />
        </Skeleton>
      </Ui.Name>
    );
  };

  const renderDescription = () => {
    if (ready) {
      return (
        <Ui.Description>
          <HtmlTruncatedText html={artist.about} />
        </Ui.Description>
      );
    }

    return (
      <Ui.Description>
        <Skeleton style={{ width: '80%', height: '20px', marginBottom: '10px' }}>
          <SkeletonRectangle width="100%" height="100%" />
        </Skeleton>
        <Skeleton style={{ width: '60%', height: '20px' }}>
          <SkeletonRectangle width="100%" height="100%" />
        </Skeleton>
      </Ui.Description>
    );
  };

  return (
    <Ui.Wrapper>
      <Ui.Container>
        <Ui.LogoWrapper>
          {showLogo && (
            <>
              <img src={brand === EBrand.Mac ? MACLogo : AvedaLogo} alt="Brand Logo" />
              <Ui.BasketWrapper>{Basket}</Ui.BasketWrapper>
            </>
          )}
        </Ui.LogoWrapper>
        <Ui.ContentWrapper>
          <Ui.CounterWrapper>
            <Ui.ProfileImageWrapper>
              <E2EWrapper id="artistPhoto">
                <Ui.Image src={ready ? artist.picture || NoImage : NoImage} alt="artist_photo" />
              </E2EWrapper>
            </Ui.ProfileImageWrapper>
          </Ui.CounterWrapper>
          <E2EWrapper id="artistName">{renderName()}</E2EWrapper>
          {renderDescription()}
          <HorizontalSocials
            // @ts-ignore
            socials={artist?.socials}
            ready={ready}
            className="artistProfileSection_socials"
          />
          {!!artist?.locations?.length && (
            <Ui.Salons>
              {artist.locations?.map((salon) => (
                <E2EWrapper id="artistOpenSalonButton" key={salon.vanity_url}>
                  <Ui.SalonLink to={`/salon/${salon.vanity_url}`}>{salon.name}</Ui.SalonLink>
                </E2EWrapper>
              ))}
            </Ui.Salons>
          )}
        </Ui.ContentWrapper>
      </Ui.Container>
    </Ui.Wrapper>
  );
};
