import styled from 'styled-components';
import { Link as Lnk } from 'react-router-dom';
import { media } from '../../utils/rwd';

export const Wrapper = styled.header`
  border-bottom: 1px solid #edeef0;
  display: flex;
  align-items: center;
  padding: 10px 0;

  color: ${({ theme }) => theme.colors.black};
  :hover {
    color: initial;
  }
  justify-content: space-between;
  ${media.lg`
    width: 100%;
    justify-content: flex-start;
 `}
`;

export const Link = styled(Lnk)`
  text-decoration: none;
  text-transform: uppercase;
  line-height: 10px;
  :hover {
    color: initial;
  }
  color: inherit;
`;

export const ArrowLeft = styled.img`
  margin-right: 6px;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
`;

export const BasketWrapper = styled.div`
  ${media.lg`
    display: none;
  `}
`;
