import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { media } from '../../utils/rwd';

export const FeedbackWrapper = styled.a`
  position: fixed;
  color: white;
  padding: 1rem;
  font-size: 24px;
  cursor: pointer;
  top: 50%;
  right: -112px;
  background: black;
  transform: rotate(270deg);
  z-index: 9999;
  &:hover {
    color: white;
  }
`;

export const StyledPopup = styled(Popup)`
  &-content {
    background: #f1f1f1;
    position: fixed !important;
    right: 90px;
    top: 30% !important;
    left: unset !important;
    width: 498px;

    ${media.sm.down`
    width: 100%;
    right: 0px;
`}
  }
`;

export const RightCorner = styled.div`
  background: #f1f1f1;
  position: fixed !important;
  top: 45%;
  right: 80px;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  z-index: -1;
`;

export const PopupHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: white;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow: scroll;
  max-height: 50vh;
`;

export const Text = styled.p`
  margin-bottom: 0px;
`;

export const TextArea = styled.textarea`
  padding: 10px;
`;

export const SubmitButton = styled.button`
  padding: 10px;
  background: black;
  color: white;
  text-transform: uppercase;
  width: 35%;
  align-self: flex-end;
`;
