type TStatusWrapper = {
  status: number;
  componentsForStatus?: { [status: string]: JSX.Element };
  defaultNotFound: JSX.Element;
  children: JSX.Element;
  isReady?: boolean;
};

export const StatusWrapper = ({
  status,
  componentsForStatus,
  defaultNotFound,
  children,
}: TStatusWrapper) =>
  status === 200 ? children : componentsForStatus?.[status] || defaultNotFound;
