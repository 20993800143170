import styled from 'styled-components';
import { media } from '../../utils/rwd';
import Ctr from '../Container/Container';

export const Wrapper = styled.div`
  background: black;

  padding-top: 30px;
  padding-bottom: 30px;

  ${media.lg`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

export const Container = styled(Ctr)`
  padding-right: 0;
`;

export const SectionTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 20px;
  color: #fff;
`;

export const StylistRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 10px;
`;

export const CarouselWrapper = styled.div`
  position: relative;
`;
