import React, { ChangeEvent, useRef, useState } from 'react';
import { MdClose, MdComment } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { postFeedback } from '../../api/requests';
import {
  FeedbackWrapper,
  PopupContent,
  PopupHeader,
  RightCorner,
  StyledPopup,
  SubmitButton,
  Text,
  TextArea,
} from './Feedback.styles';

export const Feedback = ({ uid, token }: { uid: string; token: string }): JSX.Element => {
  const { t } = useTranslation(['components']);

  const [open, setOpen] = useState(false);

  const feedback = useRef('');

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    feedback.current = event.target.value;
  };

  const closeModal = () => setOpen(false);
  const sendFeedback = () => {
    postFeedback(uid, feedback.current, token)
      .then((axiosResponse) => axiosResponse)
      .then((response) => {
        if (response.status === 200) {
          toastr.success(t('feedback.success'), t('feedback.feedbackAdded'));
          feedback.current = '';
        } else {
          toastr.error(t('feedback.error'), t('feedback.somethingWentWrong'));
        }
        closeModal();
      })
      // eslint-disable-next-line
      .catch((_) => {
        toastr.error(t('feedback.error'), t('feedback.somethingWentWrong'));
        closeModal();
      });
  };

  return (
    <>
      <FeedbackWrapper onClick={() => setOpen((o) => !o)}>
        <MdComment style={{ marginRight: '10px' }} />
        {t('feedback.enterFeedback')}
      </FeedbackWrapper>
      <StyledPopup open={open} closeOnDocumentClick onClose={closeModal}>
        <>
          <RightCorner />
          <PopupHeader>
            <h3>{t('feedback.add')}</h3>
            <MdClose size={30} style={{ cursor: 'pointer' }} onClick={() => closeModal()} />
          </PopupHeader>
          <PopupContent>
            <Text>{t('feedback.addComment')}</Text>
            <TextArea placeholder={t('feedback.placeholder')} onChange={onCommentChange} rows={8}>
              {feedback.current}
            </TextArea>
            <SubmitButton onClick={sendFeedback}>{t('feedback.add')}</SubmitButton>
          </PopupContent>
        </>
      </StyledPopup>
    </>
  );
};
