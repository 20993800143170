import styled from 'styled-components';

import { media } from '../../utils/rwd';
import { transparentButton } from '../../utils/styled-components/mixins';

const GAP_VALUE_SM = 12;
const GAP_VALUE_MD = 20;

export const LookListWrapper = styled.div`
  margin: -24px 0;
`;

export const LookList = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.sm`
    padding: 35px ${GAP_VALUE_SM}px;
    gap: 5px 8px;

  `}

  ${media.md`
  padding: 70px ${GAP_VALUE_MD}px;
`}

  ${media.lg`
    padding: 70px 0px;
  `}
`;

export const LookListItem = styled.div<{ compactView: boolean }>`
  flex: ${({ compactView }) => (compactView ? '0 0 33.3%' : '0 0 100%')};
  max-width: 100%;
  ${media.sm`
  flex: 0 0 calc(33.3% - 6px);
  `}
`;

export const ContentWrapper = styled.div.attrs({ className: 'looks-list-item__content-wrapper' })<{
  compactView: boolean;
}>`
  display: ${({ compactView }) => (compactView ? 'none' : 'flex')};
  padding: 0 20px 40px 20px;
  background: #fff;
  flex-direction: column;
  ${media.sm`
    padding: 0;
    display: flex;
`}
`;

export const ImageWrapper = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  position: relative;
  border: none;
  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    height: 100%;
  }
`;

export const ShopNowBar = styled.button`
  border: none;
  width: 100%;
  background: #000;

  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  font-weight: 700;
  z-index: 2;
  position: relative;
  span {
    color: #fff;
  }
  ${media.sm`
   display:none;

  `}
`;

export const ViewsBar = styled.div.attrs({ className: 'looks-list-item__views-bars' })`
  width: 100%;
  font-size: 14px;
  padding: 15px 20px;
  display: flex;
  justify-content: flex-end;
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  font-weight: 700;
  border-bottom: 1px solid #edeef0;
  ${media.sm`
   display:none;

  `}
`;

export const Name = styled.p`
  font-size: 14px;
  padding: 10px 0 15px 0;
  margin: 0;
  font-size: ${({ theme }) => theme.artistLookItem.title.fontSize};
  font-weight: ${({ theme }) => theme.artistLookItem.title.fontWeight};
  font-family: ${({ theme }) => theme.artistLookItem.title.fontFamily};

  font-weight: 700;
  text-transform: uppercase;
  ${media.lg`
    font-weight: 400;

  `}
`;

export const Description = styled.p.attrs({ className: 'looks-list-item__description' })`
  color: #747474;
  font-size: 16px;
  line-height: 21px;
  margin: 0;
  ${media.sm`
   display:none;

  `}
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 12px 20px;

  ${media.sm`
    display: none;
  `}
`;

export const ChangeViewButton = styled.button<{ active: boolean }>`
  background: transparent;
  border: none;
  padding: 0 20px;
  opacity: ${({ active }) => (active ? 1 : 0.3)};
`;

export const ShareButton = styled.button`
  ${transparentButton}

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #346bf6;
`;
