import React, { FC, useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../..';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';

import * as Ui from './Tabs.styles';

type TTabsProps = {
  tabs: Array<{ text: string; id?: string }>;
  breakPointForColumnDirection?: 'xs' | 'sm' | 'md' | 'lg';
  tabSelectedCallback?: (idx: number) => void;
};

export const Tabs: FC<TTabsProps> = ({
  tabs,
  children,
  breakPointForColumnDirection = 'xs',
  tabSelectedCallback = () => {},
  ...rest
}) => {
  const [active, setActive] = useState(0);
  const [underlinePosition, setUnderlinePosition] = useState<number>(0);
  const [mounted, setMounted] = useState(false);
  const showTabsInColumn = useMediaQuery({
    query: `(max-width: ${breakpoints[breakPointForColumnDirection]}px)`,
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  const updateUnderlinePosition = useCallback(() => {
    const getTab = () => {
      const tabCollectionElement = document.getElementsByClassName(
        'active',
      ) as HTMLCollectionOf<HTMLElement>;
      const tab = tabCollectionElement.item(0);
      return tab;
    };
    const tab = getTab();
    setUnderlinePosition((tab?.offsetLeft || 0) + (tab?.offsetWidth || 0) / 2 - 21);
  }, []);

  useEffect(() => {
    if (mounted) {
      window.addEventListener('resize', () => {
        updateUnderlinePosition();
      });
      updateUnderlinePosition();

      return () => {
        window.removeEventListener('resize', () => updateUnderlinePosition());
      };
    }
  }, [mounted, active]);

  return (
    <div {...rest}>
      <Ui.TabNav>
        <Ui.TabItemWrapper flexColumn={showTabsInColumn}>
          {mounted && !showTabsInColumn && <Ui.ActiveElementUnderline left={underlinePosition} />}
          {tabs.map((tab, index) => (
            <E2EWrapper id={`${tab.id}Tab`} key={tab.text}>
              <Ui.TabNavItem
                className={index === active ? 'active' : ''}
                active={index === active}
                flexColumn={showTabsInColumn}
                onClick={(e) => {
                  const { target } = e;

                  if (index !== active) {
                    setActive(index);
                    setUnderlinePosition(
                      (target as HTMLButtonElement).offsetLeft +
                        (target as HTMLButtonElement).offsetWidth / 2 -
                        21,
                    );
                    tabSelectedCallback(index);
                  }
                }}
              >
                <h2>{tab.text}</h2>
              </Ui.TabNavItem>
            </E2EWrapper>
          ))}
        </Ui.TabItemWrapper>
        <Ui.TabBody>
          <>{React.Children.map(children, (child, i) => (i === active ? child : null))}</>
        </Ui.TabBody>
      </Ui.TabNav>
    </div>
  );
};
