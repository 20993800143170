import { AxiosPromise } from 'axios';
import { referrerInstance } from '../services/ReferrerService';
import { api } from './axios';

export const getStore = async (storeId: string): Promise<AxiosPromise> =>
  api.get(`/v3/profile_info/${storeId}`);

export const getBundle = async (shortId: string, token?: string | null): Promise<AxiosPromise> =>
  api.get(`/v2/store/${shortId}${token ? `?token=${token}` : ''}`);

export const getArtistLook = async (
  shortId: string,
  token?: string | null,
): Promise<AxiosPromise> =>
  api.get(`/v3/retailer_stores/${shortId.toLowerCase()}/single${token ? `?token=${token}` : ''}`);

export const getLocation = (vanity_url: string): Promise<AxiosPromise> =>
  api.get(`/v3/location/${vanity_url}/public`);

export const getStoreByVanityURL = async (vanity_url: string): Promise<AxiosPromise> =>
  api.get(`/v3/profile_info/${vanity_url}`);

export const getPrivateStore = async (vanity_url: string, token: string): Promise<AxiosPromise> =>
  api.get(`/v3/profile_info/${vanity_url}?token=${token}`);

export const postFeedback = async (
  stylistUid: string,
  feedback: string,
  token: string,
): Promise<AxiosPromise> =>
  api.post(`/v3/profile_info/${stylistUid}/feedback?token=${token}`, {
    feedback,
  });

export const saveCart = async (
  items: Array<{
    baseCurrency: 'USD' | 'EUR';
    quantity: number;
    price: string;
    bundle_short_id: string;
    product_code: string;
  }>,
  source?: string,
): Promise<AxiosPromise> => {
  const referrer = referrerInstance.getReferrer();
  return api.post(`/v3/cart`, {
    items: items.map((item) => ({
      ...item,
      bundle_short_id: item.bundle_short_id.toUpperCase(),
    })),
    source,
    referrer,
  });
};

export const getLearningCenter = async () => api.get('/v3/learning_center');

export const getTerms = async () => api.get('/v2/term/latest');

export const getMediaResource = async (uid: string) => api.get(`/v3/brand_content/${uid}`);

export const getCustomArtists = async (locationVanity: string) =>
  api.get(`/v3/location/${locationVanity}/custom_artists`);

export const getFeaturedArtistsRequest = async () => api.get(`/v3/featured_artist`);

export const createStartedCartRequest = async (payload) => api.post('/v3/started_cart', payload);

export const getSalonGroupsRequest = async (uid: string) => api.get(`/v3/owner_group_page/${uid}`);
