import styled from 'styled-components/macro';

export const TabNav = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const TabNavItem = styled.button<{ active: boolean; flexColumn: boolean }>`
  outline: 0 !important;
  background: transparent;
  padding: 0;
  border: none;
  padding: 10px 58px;

  h2 {
    font-family: ${({ theme }) => theme.fontFamily?.bold};
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.3;
    line-height: 1.2;
    white-space: nowrap;
    text-transform: uppercase;
    color: ${({ active, theme }) => (active ? theme.colors.black : '#A8A8A8')};
    box-sizing: border-box;
    margin: 0;
    transition-duration: 600ms;
    transition-timing-function: ease-in-out;
    position: relative;
    ${({ active, flexColumn }) =>
      active &&
      flexColumn &&
      `
      ::after {
        content: '';
        border-bottom: 3px solid #000;
        width: 42px;
        display: block;
        position: absolute;
        left: calc(50% - 21px);
        bottom: -10px;
      }
    `}
`;

export const TabItemWrapper = styled.div<{ flexColumn: boolean }>`
  border-bottom: 1px solid #edeef0;
  display: flex;
  flex-direction: ${({ flexColumn }) => (flexColumn ? 'column' : 'row')};
  justify-content: center;
  position: relative;
`;

export const TabBody = styled.div`
  padding: 24px 0;
`;

export const ActiveElementUnderline = styled.div<{ left: number }>`
  background-color: #000;
  height: 3px;
  width: 42px;
  position: absolute;
  bottom: -2px;
  left: ${({ left }) => `${left}px`};

  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
`;
