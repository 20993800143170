import qs from 'qs';
import { TLook } from '../typings/types/Look';
import { TLookShort } from '../typings/types/LookShort';
import { TArtistLook } from '../typings/types/ArtistLook';

export const isLookAccessible = (look: TArtistLook | TLookShort): boolean =>
  look.is_visible && !look.is_private && !look.is_draft && !look.is_single_product;

export const createLookLink = (
  bundle: TLook,
  preview: boolean,
  addFeedback: boolean,
  token: string | null,
  prefixes: {
    artistName?: string;
    locationName?: string;
  },
): string => {
  const params = { preview, feedback: addFeedback, owner_token: token };
  const filtered = Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
  const { artistName, locationName } = prefixes;

  let prefix = '';
  if (artistName) prefix = `/a/${artistName}`;
  if (locationName) prefix = `/salon/${locationName}`;

  return `${prefix}/look/${bundle?.short_id}?${qs.stringify(filtered)}`;
};
